import { typeTicketCode } from "../helpers/constants";
import { getModelTypeByCode } from "../helpers/utils";
import { mapGetters } from "vuex";

export const withdrawMixin = {
  props: {
    withdraw: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters("auth", ["isCurrentUserMerchant", "currency"]),
    canCreateTicket() {
      return this.withdraw.status !== "Echec";
    },
    activeClass() {
      switch (this.withdraw.status) {
        case "Succès":
          return "success";
        case "Echec":
          return "danger";
        case "En cours":
          return "info";
      }
    },
    statusClassIcon() {
      switch (this.withdraw.status) {
        case "Succès":
          return "fas fa-thumbs-up text-success";
        case "Echec":
          return "fas fa-thumbs-down text-danger";
        case "En cours":
          return "fas fa-hourglass-half text-info";
      }
    },
    getRateClass() {
      if (this.withdraw.is_low_rate) return "text-info fa-long-arrow-alt-down";
      else return "text-warning fa-long-arrow-alt-up";
    },
    feeCurrency() {
      return this.isCurrentUserMerchant
        ? this.currency
        : this.withdraw.currency;
    },
  },
  methods: {
    openTicketFromThis() {
      const query = {
        model_type: getModelTypeByCode(typeTicketCode.withdraw),
        model_id: this.withdraw.id,
        operator_id: this.withdraw.information.operators_id,
        api_code: this.withdraw.api_code,
        type: typeTicketCode.withdraw,
      };
      this.$router.push({ name: "assistance", query: query });
    },
  },
};
