<template>
  <div>
    <div class="md-modal md-effect-11 md-show">
      <div class="md-content">
        <h3 class="bg-dark">
          {{ $t("withdraw.withdraw_details") }} -
          <span class="f-16 d-inline d-sm-none">
            {{ withdraw.client_transaction_id }}
          </span>
          <span class="d-none d-sm-inline">
            {{ withdraw.client_transaction_id }}
          </span>
        </h3>
        <div class="card-body p-0">
          <table class="table table-striped table-hover">
            <tbody>
              <tr>
                <td class="align-middle text-right">
                  <i>{{ $t("common.operator") }}</i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  {{ withdraw.information.operator.label }}
                </td>
              </tr>
              <tr>
                <td class="align-middle text-right">
                  <i>{{ $t("common.phone_number") }}</i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  {{ withdraw.information.phone }}
                </td>
              </tr>
              <tr>
                <td class="align-middle text-right">
                  <i>{{ $t("withdraw.withdraw_amount") }}</i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  {{ withdraw.amount + " " + feeCurrency }}
                </td>
              </tr>
              <tr>
                <td class="align-middle text-right">
                  <i class="d-inline d-sm-none">{{ $t("common.date") }} </i>
                  <i class="d-none d-sm-inline">
                    {{ $t("common.creation_date") }}
                  </i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  {{ withdraw.created_at | moment("DD MMMM YYYY") }}
                  <small class="d-block">
                    <i class="fa fa-clock f-w-300"></i>
                    {{ withdraw.created_at | moment("H:m:s") }}
                  </small>
                </td>
              </tr>
              <tr>
                <td class="align-middle text-right">
                  <i>{{ $t("common.status") }}</i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  <i :class="statusClassIcon"></i> {{ withdraw.status }}
                </td>
              </tr>
              <tr>
                <BaseCreateTicketLine
                  v-if="canCreateTicket"
                  @click="openTicketFromThis"
                />
              </tr>
            </tbody>
          </table>
          <div class="card-footer text-center">
            <button
              class="btn btn-primary"
              role="button"
              type="button"
              @click.prevent="close"
            >
              {{ $t("common.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="md-overlay" @click.prevent="close"></div>
  </div>
</template>

<script>
import { withdrawMixin } from "../../mixins/withdrawMixin";

export default {
  name: "WithdrawDetails",
  mixins: [withdrawMixin],
  methods: {
    close() {
      this.$router.back();
    },
  },
};
</script>

<style scoped></style>
