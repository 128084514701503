import { render, staticRenderFns } from "./WithdrawDetails.vue?vue&type=template&id=599c328b&scoped=true&"
import script from "./WithdrawDetails.vue?vue&type=script&lang=js&"
export * from "./WithdrawDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "599c328b",
  null
  
)

export default component.exports